<template>
    <div>
      <div class="swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="https://qiniu.youjiamall.cn/bkban1.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="mai_wrap">
        <pubBoxTit title="图书卡券样式" ltit="COUPONS" paddingTop="30"></pubBoxTit>
        <div class="mai_box top_mai_box book_mai">
          <holiConven pageType="book"></holiConven>
        </div>
      </div>

      <div class="mai_wrap">
        <pubBoxTit title="定制服务" ltit="CUSTOM SERVICES" paddingTop="30"></pubBoxTit>
        <div class="mai_box top_mai_box">
          <services></services>
        </div>
      </div>
      <div class="mai_wrap book-classify">
        <pubBoxTit title="海量图书分类" ltit="BOOK CLASSIFICATION" paddingTop="30"></pubBoxTit>
        <div class="mai_box top_mai_box book-classify">
          <BookClassify></BookClassify>
        </div>
      </div>

      <div class="mai_wrap">
        <pubBoxTit title="优质出版社" ltit="PUBLISHING HOUSE" paddingTop="30"></pubBoxTit>
        <div class="mai_box mai_hei">
          <weUser arr-type="3"></weUser>
        </div>
      </div>
      
      <pubFormInput></pubFormInput>
    </div>
</template>

<script>
import pubBoxTit from '@/components/pubBoxTit.vue'
import pubFormInput from "@/components/pubFormInput";

import weUser from '@/components/hmComponent/weUser.vue'
import holiConven from '@/components/HoliComponents/holiConven.vue'
import services from '@/components/HoliComponents/services.vue'
import BookClassify from '../components/HoliComponents/bookClassify.vue';


export default {
  name:'Cake',
    components: {
      weUser,
      pubBoxTit,
      pubFormInput,
      holiConven,
      services,
      BookClassify
    },
  metaInfo () {
    return {
      title:"优加惠品",
      meta: [{
        name: 'keyWords',
        content: '优加惠品,福利,年节慰问品,电影,蛋糕福利'
      },
        {
          name: 'description',
          content: '优加惠品,优加,福利,慰问品,年节慰问品,电影,提货券,蛋糕卡,电影卡,生日卡,生日券,蛋糕券,全福卡,全福券,蛋糕,电影福利,蛋糕福利,元宵节慰问品,龙抬头慰问品,端午慰问品节,七夕节慰问品,三八妇女节,五一劳动节,中秋节慰问品,重阳节慰问品,下元节慰问品,冬至节慰问品,腊八节慰问品,小年慰问品,除夕慰问品,春节慰问品,元宵节,龙抬头,端午节,七夕节,中秋节,重阳节,三八妇女节,五一劳动节,下元节,冬至节,腊八节,小年,员工福利,员工体检,企业团险,员工团建活动,夏送清凉,冬送温暖'
        }],
      link: [{ // set link
        rel: 'asstes',
        href: 'https://assets-cdn.github.com/'
      }]
    }
  },
    data() {
        return {
          current:0,
          selArr:["线上选蛋糕","门店消费"]
        };
    },

    methods: {
      mouseChange(index) {
        this.current = index;
      }
    }
}

</script>
<style scoped>
.mai_box {
  padding: 0 120px;
  box-sizing: border-box;
}
.top_mai_box {
  padding: 65px 134px 40px;
  box-sizing: border-box;
  background:#fff;
  
}
.book_mai {
  background: url('https://qiniu.youjiamall.cn/tsbg.png')no-repeat;
  background-size: 100% 100%;
}
.cake_wrap {
  height: 684px;
  background: #E9EFF4;
}
.cake_title {
  position: relative;
}
.tit_right_btn {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}
.tit_right_btn p{
  cursor: pointer;
  width: 106px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666;
  margin-right: 10px;
}
.tit_right_btn .btnActive {
  background: #007858;
  color: #fff;
}


.sub_box {
  margin-top: 26px;
}

.mai_hei {
  height: 640px;
}

.swiper img{
  width: 100%;
  height: 524px;
  object-fit: inherit;
}


.book-classify {
  background: #F9FCFF;
}
</style>
