<template>
  <div class="mai_content">
    <div class="mai_left">
      <img src="https://qiniu.youjiamall.cn/bookfl11.png" alt="">
      <img src="https://qiniu.youjiamall.cn/bookfl22.png" alt="">
      <img src="https://qiniu.youjiamall.cn/bookfl33.png" alt="">
      <img src="https://qiniu.youjiamall.cn/bookfl44.png" alt="">
    </div>
    <div class="mai_rig">
      北京优加惠品自营图书甄选100W+优质图书，图书资源丰富，包括<span style="color: #000;">科学与自然、社会科学、艺术、经济、法律、心理学、计算机与互联网、历史、青春文学、励志与成功、建筑、政治/军事、童书、书法等百余门类，</span>优加图书为员工选购图书提供全方位保障，满足了员工多样化的阅读需求，为员工提供了一个良好的阅读采购平台，通过阅读的力量促进了员工的个人成长和行业发展。
    </div>
  </div>
</template>

<script>
export default {
  name: "holiConven",
  data(){
    return {
    }
  },
  methods:{
  }
}
</script>

<style scoped>
.mai_content {
  width: 100%;
  display: flex;
}

.mai_left {
  width: 55%;
  margin-right: 30px;
}
.mai_left img {
  width: 100%;
  margin-bottom: 12px;
}
.mai_rig {
  font-family: Adobe Heiti Std;
  font-size: 16px;
  color: #3a3a3a;
  line-height: 28px;
  padding: 50px;
  box-sizing: border-box;
  width: 480px;
  text-indent: 2em;
  margin-left: 70px;
  background: url('https://qiniu.youjiamall.cn/tsflbg.png')no-repeat;
  background-size: 100% 100%;
}
</style>
