<template>
  <div class="mai_content">
    <div class="mai_left">
      <ul>
        <li v-for="(item,index) in list" :class="{active:current == index}" @mousemove="mouseChange(index,item.imgurl)" :key="item.imgurl">
          <img v-if="current == index" src="https://qiniu.youjiamall.cn/fwicon.png" alt="">
          {{ item.name }}
        </li>
      </ul>
    </div>

    <div class="mai_righ">
      <img :src="imgurl" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "holiConven",
  data(){
    return {
      current:0,
      imgurl:"https://qiniu.youjiamall.cn/tsfw111.png",
      list:[{
        name:'定制书签',
        imgurl:'https://qiniu.youjiamall.cn/tsfw111.png'
      },{
        name:'作者签名',
        imgurl:'https://qiniu.youjiamall.cn/zjqm1.png'
      },{
        name:'定制腰封',
        imgurl:'https://qiniu.youjiamall.cn/tsfw33.png' 
      },{
        name:'卡券定制',
        imgurl:'https://qiniu.youjiamall.cn/tsfw44.png'
      },{
        name:'卡套定制',
        imgurl:'https://qiniu.youjiamall.cn/tsfw55.png'
      }]
    }
  },
  methods:{
    mouseChange(index,url) {
      this.current = index;
      this.imgurl = url;
    }
  }
}
</script>

<style scoped>
.mai_content {
  width: 100%;
  height: 449px;
  display: flex;
}
.mai_left {
  /* flex: 1; */
  width: 100%;
  margin-right: 32px;
}
.mai_left li{
  position: relative;
  height: 73px;
  width: 100%;
  line-height: 73px;
  border-radius: 10px;
  font-family: Adobe Heiti Std;
  font-size: 20px;
  color: #000000;
  padding-left: 74px;
  box-sizing: border-box;
}
.mai_left img {
  position: absolute;
  left: 37px;
  top: 50%;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
}
.mai_left .active {
  background: #007858;
  color: #fff;
}

.mai_righ {
  width: 42%;
  /* width: 500px; */
  /* flex: 1; */
  width: 100%;
  border-radius: 10px 10px 10px 10px;
}
.mai_righ img {
  width: 100%;
  height: 364px
}
</style>
