<template>
  <div>
    <div class="mai_content book_content" v-if="pageType == 'book'">
      <div class="content_top">
        <div class="mai_card"></div>
        <div class="mai_xian" style="background: url(https://qiniu.youjiamall.cn/tsyuan.png)no-repeat;background-size: 100% 100%;">
          <!-- <img style="height: 388px;width: 40px;" src="https://qiniu.youjiamall.cn/tsyuan.png" alt=""> -->
        </div>
        <div class="mai_card"></div>
      </div>
      <div class="content_btm">
        <p>图书福利我们有图书卡、图书券不同形式，员工可以通过图书卡券进入优加图书选购喜欢的书籍，</p>
        <p>我们为员工准备了百万+本图书商品，“读一本好书，如同像交了一个益友”。</p>
        <p>读书能让我们感受到心灵深处的快乐，能在读书中学会思考，能在学习中学会悟道。</p>
        <p>每一个美好思想、每一幅美丽的人生画卷，都能由读书带给我们。</p>
      </div>
    </div>
    <!-- <div class="mai_content book_content" v-if="pageType == 'book'">
      <div class="content_top">
        <div class="mai_card"></div>
      </div>
      <div class="content_btm">
        <p>图书福利我们有图书卡、图书券不同形式，员工可以通过图书卡券进入优加图书选购喜欢的书籍，</p>
        <p>我们为员工准备了百万+本图书商品，“读一本好书，如同像交了一个益友”。</p>
        <p>读书能让我们感受到心灵深处的快乐，能在读书中学会思考，能在学习中学会悟道。</p>
        <p>每一个美好思想、每一幅美丽的人生画卷，都能由读书带给我们。</p>
      </div>
    </div> -->
    <div class="mai_content" v-if="pageType == 'th'">
      <div class="content_top">
        <div class="mai_card"></div>
        <div class="mai_xian">
          <img src="@/assets/img/th_xian.png" alt="">
        </div>
        <div class="mai_card"></div>
      </div>
      <div class="content_btm">
        <p>年节慰问福利</p>
        <span>整合优质产品资源，在不同节日、场景为企业提供全方位礼品服务解决方案，<br> 如节日慰问、员工福利、客户答谢、企业礼品、馈赠亲友等，全国配送，邮寄到家，发放方便，操作简单。</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "holiConven",
  props:{
    pageType:{
      type:String,
      default:""
    }
  }
}
</script>

<style scoped>
.mai_content {
  width: 100%;
  height: 449px;
  background: rgba(255,255,255,0.5);
  border-image: linear-gradient(-43deg, #FFFFFF, #FFFFFF, #FFFFFF) 2 2;
  box-shadow: 0px 9px 27px 0px rgba(0,0,0,0.14);
  border-radius: 10px;
  padding: 50px 103px 40px;
  box-sizing: border-box;
}
.content_top {
  display: flex;
  justify-content: space-between;
}
.content_btm {
  margin-top: 35px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.content_btm span {
  display: inline-block;
  /*width: 500px;*/
  margin-top: 22px;
  font-size: 14px;
  line-height: 24px;
}
.mai_card {
  width: 442px;
  height: 221px;
  background: url("https://qiniu.youjiamall.cn/yjpc/qfcard.png")no-repeat;
  background-size: 100% 100%;
}
.mai_card:last-child {
  background: url("https://qiniu.youjiamall.cn/yjpc/thcard.png")no-repeat;
  background-size: 100% 100%;
}
.mai_xian {
  /*background: url("")no-repeat;*/
  /*background-size: 100% 100%;*/
  /*width: 13px;*/
  /*height: 172px;*/
  display: flex;
}
.book_content .mai_xian {
  width: 13px;
  height: 214px;
}
.book_content {
  height: 470px;
}
.mai_xian img {
  /*width: 13px;*/
  /*height: 198px;*/
  margin: auto;
}

.book_content p {
  margin-bottom: 10px;
  font-size: 16px;
}

.book_content .mai_card {
  width: 442px;
  height: 221px;
  background: url("https://qiniu.youjiamall.cn/tslx33.png")no-repeat;
  background-size: 100% 100%;
}
.book_content .mai_card:last-child {
  width: 342px;
  height: 221px;
  background: url("https://qiniu.youjiamall.cn/tslx22.png")no-repeat;
  background-size: 100% 100%;
}
</style>
